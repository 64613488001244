@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.root {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.root[data-reach-dialog-overlay] {
	z-index: 9997;
	overflow: hidden;
	background: rgba(41, 44, 51, 0.6);
}

.rootBanner {
	top: 57px;
}

.dialog {
	font-family: 'Futura PT';
	height: auto;
	max-height: 84%;
	padding: 0;
	outline: 0;
	overflow: hidden;
	min-width: 1024px;
	background: #ffffff;
	border-radius: 8px;
	margin: 0 32px;
	min-height: 480px;

	@include maxWidth($tablet + 1) {
		min-width: 95%;
	}

	@include maxWidth($mTablet + 1) {
		min-width: 99%;
	}
}

.close {
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 16px;
}

.header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background: $gray-1;
	border-radius: 4px 4px 0px 0px;
	padding: 32px 88px 32px 48px;
	position: relative;

	@include h3;
	color: $black-1;
	letter-spacing: 0.16px;

	@include maxWidth($tablet + 1) {
		padding: 32px 70px 32px 32px;
	}

	@include maxWidth($mTablet + 1) {
		padding: 24px 70px 24px 24px;
	}

	@include maxWidth($sMobile) {
		padding: 24px;
	}

	.title {
		font-size: 32px;
		font-weight: 450;

		@include maxWidth($mTablet + 1) {
			font-size: 24px;
		}
	}
}

.main {
	padding: 24px 48px 24px 0;
	display: flex;
	position: relative;
	margin-left: 48px;
	border-bottom: 1px solid $gray-1;

	@include maxWidth($tablet + 1) {
		margin-left: 32px;
	}

	@include maxWidth($mTablet + 1) {
		margin-left: 24px;
	}

	@include maxWidth($mobile) {
		margin-left: 20px;
	}
}

.main:last-child {
	border-bottom: none;
}

.removeProduct {
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
}

.button {
	width: 313px;

	@include maxWidth(700px) {
		width: 280px;
	}
}

.leftSection {
	max-width: 500px;
	height: 100%;
	display: flex;
	flex-basis: 50%;
}

.image {
	height: 266px;
	width: 184px;
}

.brand {
	@include card-brand;
	line-height: 130%;
	color: $black-1;

	@include maxWidth($mobile) {
		@include card-brand-mobile;
	}
}

.name {
	@include card-text;
	color: $black-3;
	padding: 8px 0 8px;

	@include maxWidth($mobile) {
		@include card-text-mobile;
	}
}

.priceBlock {
	display: flex;
}

.price {
	display: flex;
	align-items: center;
	font-size: 16px;
}

.discountPercent {
	margin-right: 8px;
}

.oldPrice {
	text-decoration-line: line-through;
	color: $black-3;
	margin-right: 10px;
}

.newPrice {
	color: #292c33;
}

.sale {
	color: $red-1;
}

.rightSection {
	margin-left: 42px;
	display: flex;
	flex-direction: column;
	width: 500px;
	height: 100%;

	@include maxWidth($tablet + 1) {
		margin-left: 30px;
		width: 386px;
	}

	@include maxWidth($mTablet + 1) {
		width: 313px;
		margin-left: 24px;
		min-width: 313px;
	}

	@include maxWidth($mobile + 1) {
		width: 100%;
		margin-left: 20px;
		margin-top: 16px;
	}
}

.color {
	font-weight: 400;
	color: #292c33;
	font-size: 18px;
	line-height: 130%;
	margin-bottom: 16px;
}

.sizeBlock {
	display: flex;
	align-items: center;
}

.size {
	border: 1px solid $gray-1;
	box-sizing: border-box;
	border-radius: 4px;
	margin: 0 4px;
	padding: 6px 16px;
}

.sizeText {
	margin-right: 12px;
}

.addToCart {
	max-width: 180px;
}

.description {
	margin-left: 16px;
	min-width: 150px;
}

.products {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	height: calc(100% - 102px);
	margin-right: 24px;
}

@media (max-width: 1024px) {
	.dialog {
		width: unset;
	}

	.image {
		width: 184px;
	}

	.leftSection {
		width: 280px;
	}
}

@media (min-width: 768px) {
	.image {
		width: 158px;
		height: 228px;
	}
}

@media (min-width: 767px) {
	.products::-webkit-scrollbar {
		background-color: #ffffff;
		width: 6px;
		border-radius: 2px;
	}

	.products::-webkit-scrollbar-thumb {
		background: $black-4;
		border-radius: 8px;
	}

	.products::-webkit-scrollbar-track {
		margin: 24px 0;
	}
}

@media (max-width: 767px) {
	.header {
		font-size: 24px;
	}

	.products {
		height: calc(100% - 144px);
		margin-right: 0;
	}

	.image {
		width: 124px;
		height: 180px;
	}

	.dialog {
		width: 100%;
		height: 100%;
		max-height: unset;
		margin-top: 50px;
	}

	.main {
		display: flex;
		flex-direction: column;
		padding: 24px;
		margin: 0;
	}

	.rightSection {
		margin-left: 0;
		min-width: unset;
	}

	.leftSection {
		flex-basis: 100%;
		width: unset;
	}
}

@media (max-width: 660px) {
	.header {
		display: flex;
		flex-direction: column;
	}

	.button {
		margin-top: 16px;
	}
}

@include maxWidth($mobile) {
	.button {
		width: 100%;
	}
}

.skeleton {
	margin-left: 32px;
	margin-top: 32px;

	.skeletonBlock {
		display: flex;
		margin-bottom: 48px;
		flex-wrap: wrap;

		.skeletonImage {
			min-width: 184px;
			height: 266px;

			@include maxWidth($mTablet + 1) {
				min-width: 158px;
			}

			@include maxWidth($mobile) {
				min-width: 124px;
			}
		}

		.skeletonDescription {
			display: flex;
			flex-direction: column;
			margin-left: 16px;
			width: 230px;

			@include maxWidth($mTablet + 1) {
				width: 100px;
			}
		}

		.skeletonSize {
			width: 480px;
			margin-left: 32px;

			@include maxWidth($tablet + 1) {
				width: 380px;
			}

			@include maxWidth(968px) {
				width: 320px;
			}

			@include maxWidth($mobile) {
				width: 80%;
				margin-top: 32px;
				margin-left: 0;
			}
		}
	}
}
